@import '../../../../scss/theme-bootstrap';
.sitewide-footer-formatter {
  &__trustmark-wrapper {
    display: none;
  }
  position: relative;
  background-color: $color-white;
  clear: both;
  color: $color-white;
  font-size: 14px;
  margin: 0 auto;
  padding-bottom: 30px;
  text-align: $ldirection;
  .active-gnav & {
    z-index: 1;
  }
  @include breakpoint($landscape-up) {
    font-size: 12px;
    margin: 0 auto;
    padding-bottom: 25px;
    width: 100%;
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    @include breakpoint($landscape-up) {
      padding-inline-start: 40px;
    }
  }
  &__top {
    flex: 0 100%;
    order: 1;
    @include breakpoint($landscape-up) {
      flex: 0 43%;
      margin-top: 10px;
      order: 2;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
    order: 2;
    width: 100%;
    @include breakpoint($landscape-up) {
      flex: 0 57%;
      margin-bottom: 75px;
      margin-top: 10px;
      order: 1;
    }
  }
  &__links {
    font-family: $font--lars;
    display: block;
    margin: 0;
    padding: 0 0 1.5em;
    text-decoration: none;
    width: 100%;
    word-wrap: break-word;
    @include breakpoint($landscape-up) {
      @include swap_direction(padding, 47px 16px 0 0);
      max-width: 100%;
      flex: 1 1 auto;
      width: auto;
    }
    &:first-child {
      padding-top: 1.8em;
      @include breakpoint($landscape-up) {
        padding-top: 47px;
      }
    }
    &:last-child {
      padding-#{$rdirection}: 0;
    }
  }
  &__bottom-wrapper {
    @include breakpoint($landscape-up) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  &__country-chooser {
    @include breakpoint($landscape-up) {
      @include swap_direction(margin, 0 33px 0 0);
      order: 2;
    }
    a {
      color: $color-black;
    }
  }
  &__recycling {
    display: flex;
    align-items: center;
    @include swap_direction(margin, 33px 0 0 0);
    @include breakpoint($landscape-up) {
      margin: 0;
      order: 3;
    }
    &-container {
      padding-#{$rdirection}: 10px;
    }
    &-text {
      p {
        margin: 0;
      }
      a {
        text-decoration: none;
        &:hover {
          box-shadow: 0 1px 0 0 $color-black;
          text-decoration: none;
        }
      }
    }
  }
  &__language-toggle {
    width: auto;
    float: $rdirection;
    display: inline-block;
    @include breakpoint($landscape-up) {
      order: 3;
    }
  }
  &__copyright {
    width: 100%;
    display: inline-block;
    @include swap_direction(margin, 35px 0 0 0);
    color: $color-black;
    @include breakpoint($landscape-up) {
      @include swap_direction(margin, 0 33px 0 0);
      order: 1;
      width: auto;
    }
    p {
      @include breakpoint($landscape-up) {
        margin: 0;
      }
    }
  }
  &__terms-links {
    &.footer-links {
      display: inline-block;
      text-align: $ldirection;
      margin: 35px auto 0;
      width: 100%;
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, 0 0 0 auto);
        display: flex;
        justify-content: flex-end;
        flex: auto;
        width: auto;
        order: 4;
      }
    }
  }
  &__term-link {
    @include swap_direction(padding, 0 0 0 8%);
    display: inline-block;
    &:first-child {
      padding-#{$ldirection}: 0;
    }
    @include breakpoint($landscape-up) {
      @include swap_direction(padding, 0 0 0 33px);
    }
  }
}
// footer simplified on all checkout pages, including the cart page.
body {
  &#viewcart,
  &#samples,
  &#shipping,
  &#billing,
  &#review,
  &#confirm,
  &#index {
    .sitewide-footer-formatter {
      &__top,
      &__content,
      &__country-chooser,
      &__language-toggle,
      &__variants,
      &__global {
        display: none;
      }
      &__copyright {
        @include breakpoint($landscape-up) {
          display: flex;
          align-items: center;
          height: 29px;
        }
      }
      &__bottom-wrapper {
        padding-#{$ldirection}: 0;
        @include breakpoint($landscape-up) {
          padding-#{$ldirection}: 20px;
        }
      }
    }
  }
}
